import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w06RS03NeKarga = () => (
  <Layout>
    <SEO title="w06RS03NeKarga" />

    <h1 id="ueberschrift">Fantasy-Rollenspiel</h1>
    <h2>Ne-Karga &emsp;  ©&nbsp;2002</h2>

<p className="note">!!!DIESER KURS WIRD AKTUELL AUSGESETZT!!!</p>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w06RS03NeKarga&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Ob als Mensch, Elf, Zwerg, Oger oder ein anderes Wesen: erleben Sie 
        bei einem gemütlichen Beisammensein eine fantastische Welt, indem Sie sie
        als Kämpfer, Gaukler, Magiekundiger, etc. "bereisen" und ihre Rätsel lösen.
        Lassen Sie ihrer Fantasie im Rahmen des Zusammenspiels und unter Anleitung eines
        "Spielleiters" (game master GM) freien Lauf 
        und genießen Sie bei lustigen und interessanten Anekdoten die gemeinsame Zeit.
      </p>
      <p>
        Ne-Karga ist ein deutsches Pen-&amp;-Paper-Rollenspiel, dessen
        Regelwerk diesem Kurs als Grundlage dient.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz/Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: + ggf. ein W100 oder zwei 10-seitige Würfel +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze/Tische
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w06RS03NeKarga
